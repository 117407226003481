import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import SliderSlick from "react-slick"
import PageSlideProduct from "./PageSlideProduct"
import "../scss/components/_SlideProducts.scss"

const SlideProducts = () => {
  const data = useStaticQuery(graphql`
    query SlideProduct {
      allFile(filter: { relativeDirectory: { eq: "Product/Slide" } }) {
        edges {
          node {
            base
            childImageSharp {
              fluid(quality: 100, maxWidth: 1920) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    autoplay: false,
    fade: true,
    autoplaySpeed: 5000,
    speed: 1100,
    afterChange: current =>
      window.dataLayer.push({
        event: "GAEvent",
        event_category: "Carousel",
        event_action:
          current === 0
            ? `${current + 1} ::LA LAGER PREFERIDA EN EL MUNDO `
            : `${current + 1} :: LAS PRIMERAS BUDWEISER`,
        event_label: "",
        interaction: true,
      }),
  }

  return (
    <SliderSlick
      {...settings}
      className="Arrows"
      id="SlideProducts"
      style={{ backgroundColor: "#fff" }}
    >
      <PageSlideProduct
        title={"LA LAGER PREFERIDA EN EL MUNDO"}
        paragraph={
          "La historia de Budweiser es la historia del deseo y ambición de un hombre: Adolphus Busch. Hijo de un alemán, Busch mostró a los estadounidenses el sabor de la cerveza lager bohemia, cambiando así la forma de elaborarla y disfrutarla. De esta forma, el sueño se hace realidad."
        }
        image={data.allFile.edges[1].node.childImageSharp.fluid}
      />
      <PageSlideProduct
        title={"LAS PRIMERAS BUDWEISER"}
        paragraph={
          "A mediados de 1800, los norte americanos preferían cervezas robustas, oscuras y algunas más livianas del estilo lager. En 1876, Adolphus descubrió una oportunidad, comenzar a fermentar la lager bohemia, más liviana que las Bavarians y completamente opuestas a las Ales. Se llamó “Budweiser Larger Beer”, la cual se convirtió en la marca número uno de cervezas en Estados Unidos. Una receta tan buena que no hemos tenido que tocarla desde 1876."
        }
        image={data.allFile.edges[0].node.childImageSharp.fluid}
      />
    </SliderSlick>
  )
}

export default SlideProducts
