import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import SEO from "../components/seo"
import { Row, Col } from "react-bootstrap"
import arrowDown from "../images/icons/arrowDown.png"
import { IsMobileDevice } from "../utils/index"
import styles from "../scss/pages/_producto.module.scss"

import Layout from "../components/Layout.js"
import InfoBackgroundProduct from "../components/InfoBackgroundProduct"
import SplitSection from "../components/SplitSection"
import InfoSection from "../components/InfoSection"
import ViewProducts from "../components/ViewProducts"
import SlideProducts from "../components/SlideProducts"
import AgeGate from "../components/AgeGate"
import Cookies from "js-cookie"

const Producto = ({ data }) => {
  const [isMobile, setIsMobile] = useState(null)
  const [age, setAge] = useState(true)

  useEffect(() => {
    let td = Cookies.get("_td")
    window.dataLayer.push({
      brand: "Budweiser",
      Zone: "SAZ",
      country: "ARG",
      city: "CABA",
      campaign: `${
        window.location.search.includes("?cmp=")
          ? window.location.search.split("?cmp=")[1].split("&")[0]
          : ""
      }`,
      cms: "0",
      event: "pageView",
      language: "es",
      login: false,
      pageName: "Nuestra Birra",
      pageType: "Basic page",
      SiteType: "Brand",
      product: "NA",
      sku: "NA",
      userUid: "",
      url: "https://www.budweiser.com.ar/producto",
      url_campaign: `${
        window.location.search.includes("utm_campaign=")
          ? window.location.search.split("utm_campaign=")[1]
          : ""
      }`,
      Step_number: "",
    })
  }, [])

  useEffect(() => {
    let cookie = Cookies.get("age")
    let local = sessionStorage.getItem("age")
    console.log("Cookie", cookie)
    if (cookie || local) setAge(false)
    setIsMobile(IsMobileDevice())
  }, [])

  return !age ? (
    <Layout>
      <SEO title="Product" />
      {/* banner */}
      <InfoBackgroundProduct info={data.Banner} />
      {/* arrow down */}
      <Row className="d-flex justify-content-center m-0">
        <Col xs={"auto"}>
          <a
            onClick={e =>
              document
                .querySelector("#InfoSection")
                .scrollIntoView({ behavior: "smooth" })
            }
          >
            <img src={arrowDown} className="ArrowDown" />
          </a>
        </Col>
      </Row>
      {/* info section */}
      <Row fluid className="m-0 p-0">
        <Col fluid className="m-0 p-0">
          <SplitSection
            left={
              <InfoSection
                title="NUESTRA BIRRA"
                paragraph="Budweiser es una lager elaborada con malta de
                            cebada y una mezcla de variedades de lúpulo
                            Premium. Una birra que sabe respetar los
                            tiempos de elaboración. Para alcanzar un sabor
                            con carácter y una suavidad superior, la dejamos
                            descansar junto a una madera llamada
                            Beechwood durante 21 días, el tiempo justo."
                paragraph2="Ya conocés cómo se elabora nuestra King of
                            beers, ahora probala."
              />
            }
            right={
              <Img
                fluid={data.allFile.edges[0].node.childImageSharp.fluid}
                loading="eager"
                className={styles.imgInfoSectionProducts}
              />
            }
          />
        </Col>
      </Row>
      {/* productos */}
      <Row fluid className="m-0 p-0">
        <Col fluid className="m-0 p-0">
          <ViewProducts products={data.Products} />
        </Col>
      </Row>
      {/* arrow down only mobile*/}
      {isMobile && (
        <Row className="d-flex justify-content-center m-0">
          <Col xs={"auto"}>
            <a
              onClick={e =>
                document
                  .querySelector("#slideProducts")
                  .scrollIntoView({ behavior: "smooth" })
              }
            >
              <img
                src={arrowDown}
                className="ArrowDown"
                style={{ bottom: "25px" }}
              />
            </a>
          </Col>
        </Row>
      )}
      {/* slider */}
      <Row className="m-0 p-0" id="slideProducts">
        <Col className="m-0 p-0">
          <SlideProducts products={data.Products} />
        </Col>
      </Row>
    </Layout>
  ) : (
    <AgeGate path="/producto" />
  )
}

export default Producto

export const data = graphql`
  query backgroundProduct {
    allFile(filter: { relativeDirectory: { eq: "Product" } }) {
      edges {
        node {
          base
          childImageSharp {
            fluid(quality: 100, maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    Banner: productosImage {
      linkButton
      paragraph
      textButton
      title
      image
    }
    Products: allProductosItems {
      edges {
        node {
          image
          description
          position
        }
      }
    }
  }
`
