import React from 'react';
import {Container, Row, Col, Button} from 'react-bootstrap';
import styles from '../scss/components/_InfoBackgroundProduct.module.scss';
// import Image from 'react-graceful-image';

const InfoBackgroundProduct = ({info}) => {

    return (
        <Row className={styles.Container}>
            <Col className="m-0 p-0">
            <img
                className={styles.backgroundImageProduct}
                src={info.image}
                alt='Banner Page Products'
                placeholderColor='#000'
            />
            <div className={styles.InfoGradient}>
            <Container fluid className={styles.infoContainer}>
                <Row className="w-100 m-0">
                    <Col md={6} xs={12} className={styles.InfoColumn}>
                        {/* title */}
                        {info.title &&
                            <Row>
                                <Col>
                                    <h1 className={styles.infoTitle}>{info.title}</h1>
                                </Col>
                            </Row>
                        }
                        {/* paragraph */}
                        {info.paragraph &&
                            <Row>
                                <Col>
                                    <p className={styles.infoParagraph}>{info.paragraph}</p>
                                </Col>
                            </Row>
                        }
                        {/* button */}
                        {info.textButton &&
                            <Row>
                                <Col>
                                    <Button className={styles.infoButton} href={info.linkButton}>{info.textButton}</Button>
                                </Col>
                            </Row>
                        }
                    </Col>
                </Row>
            </Container>
            </div>
            </Col>
        </Row>
    )
}

export default InfoBackgroundProduct