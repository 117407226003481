import React from 'react';
import Img from 'gatsby-image';
import {Container, Row, Col} from 'react-bootstrap';
import styles from '../scss/components/_PageSlideProduct.module.scss';

const PageSlideProduct = ({title = null, paragraph = null, image = null}) => (
    <Container className={styles.ContainerPageSlide}>
        <Row>
            <Col md={6} className={styles.ColInfo}>
                <div>
                    <Row>
                        <Col>
                            <h1 className={styles.SlideTitle}>{title}</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p className={styles.SlideParagraph}>{paragraph}</p>
                        </Col>
                    </Row>
                </div>
            </Col>
            <Col md={6} className="m-0 p-0">
                <Img fluid={image} loading="eager" className={styles.ImgSlideProducts}/>
            </Col>
        </Row>
    </Container>

)

export default PageSlideProduct