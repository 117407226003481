import React, { Fragment, useState, useEffect } from "react"
import { Link } from "gatsby"
// import Image from 'react-graceful-image';

import { Container, Row, Col, Button } from "react-bootstrap"
import styles from "../scss/components/_ViewProducts.module.scss"
import { IsMobileDevice } from "../utils/index"
import Plx from "react-plx"
import Swiper from "react-id-swiper"
import "swiper/swiper.scss"

const ViewProducts = ({ products }) => {
  const [isMobile, setIsMobile] = useState(null)

  useEffect(() => {
    setIsMobile(IsMobileDevice())
    // order by position
    products.edges.sort((a, b) => a.node.position - b.node.position)
  })

  const parallaxElements = [
    {
      start: "self",
      duration: 500,
      properties: [
        {
          freeze: false,
          startValue: 0,
          endValue: 1,
          property: "opacity",
        },
      ],
    },
  ]

  const params = {
    init: true,
    loop: true,
    slidesPerView: 1, // or 'auto'
    spaceBetween: -150,
    centeredSlides: true,
    grabCursor: true,
    effect: "coverflow", // 'cube', 'fade', 'coverflow',
    coverflowEffect: {
      rotate: 0, // Slide rotate in degrees
      stretch: 0, // Stretch space between slides (in px)
      depth: 400, // Depth offset in px (slides translate in Z axis)
      modifier: 1, // Effect multipler
      slideShadows: true, // Enables slides shadows
    },
  }

  return (
    <Fragment>
      {!isMobile ? (
        <Container fluid className={styles.ContainerProducts}>
          <Row fluid className="p-0 m-0 w-100">
            <Col fluid className="p-0 m-0 align-items-center text-center">
              {/* title */}
              <h1 className={styles.TitleProducts}>
                UNA RECETA ÚNICA, MUCHAS FORMAS DE DISFRUTARLA
              </h1>
              <h3 className={styles.SubtitleProducts}>
                CONSEGUILAS ONLINE EN @tada_ar
              </h3>
              {/* elements */}
              <Plx parallaxData={parallaxElements}>
                <Row className={styles.RowElements}>
                  {products.edges[0] && (
                    <Col md={2} className={styles.ColElement}>
                      <Row>
                        <Col md={12} className="d-flex justify-content-center">
                          <img
                            className={styles.Element}
                            style={{ width: "70%" }}
                            src={products.edges[0].node.image}
                            alt="Product"
                            placeholderColor="#000"
                            noLazyLoad={true}
                          />
                        </Col>
                        <Col md={12}>
                          <p className={styles.DescriptionElement}>
                            {products.edges[0].node.description}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  )}
                  {products.edges[1] && (
                    <Col md={2} className={styles.ColElement}>
                      <Row>
                        <Col md={12} className="d-flex justify-content-center">
                          <img
                            className={styles.Element}
                            style={{ width: "70%" }}
                            src={products.edges[1].node.image}
                            alt="Product"
                            placeholderColor="#000"
                            noLazyLoad={true}
                          />
                        </Col>
                        <Col md={12}>
                          <p className={styles.DescriptionElement}>
                            {products.edges[1].node.description}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  )}
                  {products.edges[2] && (
                    <Col md={2} className={styles.ColElement}>
                      <Row>
                        <Col md={12} className="d-flex justify-content-center">
                          <img
                            className={styles.Element}
                            style={{ width: "65%" }}
                            src={products.edges[2].node.image}
                            alt="Product"
                            placeholderColor="#000"
                            noLazyLoad={true}
                          />
                        </Col>
                        <Col md={12}>
                          <p className={styles.DescriptionElement}>
                            {products.edges[2].node.description}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  )}
                  {products.edges[3] && (
                    <Col md={2} className={styles.ColElement}>
                      <Row>
                        <Col md={12} className="d-flex justify-content-center">
                          <img
                            className={styles.Element}
                            style={{ width: "75%" }}
                            src={products.edges[3].node.image}
                            alt="Product"
                            placeholderColor="#000"
                            noLazyLoad={true}
                          />
                        </Col>
                        <Col md={12}>
                          <p className={styles.DescriptionElement}>
                            {products.edges[3].node.description}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  )}
                  {products.edges[4] && (
                    <Col md={2} className={styles.ColElement}>
                      <Row>
                        <Col md={12} className="d-flex justify-content-center">
                          <img
                            className={styles.Element}
                            style={{ width: "80%" }}
                            src={products.edges[4].node.image}
                            alt="Product"
                            placeholderColor="#000"
                            noLazyLoad={true}
                          />
                        </Col>
                        <Col md={12}>
                          <p className={styles.DescriptionElement}>
                            {products.edges[4].node.description}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  )}
                  {products.edges[5] && (
                    <Col md={2} className={styles.ColElement}>
                      <Row>
                        <Col md={12} className="d-flex justify-content-center">
                          <img
                            className={styles.Element}
                            style={{ width: "100%" }}
                            src={products.edges[5].node.image}
                            alt="Product"
                            placeholderColor="#000"
                            noLazyLoad={true}
                          />
                        </Col>
                        <Col md={12}>
                          <p className={styles.DescriptionElement}>
                            {products.edges[5].node.description}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  )}
                </Row>
              </Plx>
              {/* button */}
              <Button
                target="__blank"
                href="https://www.tada.com.ar/catalogo/grupo/budweiser"
                className={styles.ButtonProducts}
                onClick={() => {
                  window.dataLayer.push({
                    event: "GAEvent",
                    event_category: "Product",
                    event_action: "Content",
                    event_label: "Comprar",
                    interaction: true,
                    component_name: "boton_comprar",
                    element_text: "Comprar",
                  })
                }}
              >
                Comprar
              </Button>
            </Col>
          </Row>
        </Container>
      ) : (
        <Container
          style={{ width: "100vw" }}
          fluid
          className={styles.ContainerProducts}
        >
          <Swiper {...params}>
            {products.edges.map(item => (
              <Row className="text-center ml-0">
                <Col md={12} className="d-flex justify-content-center">
                  <Link
                    target="__blank"
                    to="https://www.tada.com.ar/catalogo/grupo/budweiser"
                  >
                    <img
                      className={styles.Element}
                      src={item.node.image}
                      alt="Product"
                      placeholderColor="#000"
                    />
                  </Link>
                </Col>
                <Col md={12}>
                  <p className={styles.DescriptionElement}>
                    {item.node.description}
                  </p>
                </Col>
              </Row>
            ))}
          </Swiper>
        </Container>
      )}
    </Fragment>
  )
}

export default ViewProducts
